import { navigate } from "gatsby"
import React from "react"
import { Container } from "react-bootstrap"
import Layout from "../components/utils/layout"
import "antd/dist/antd.css"
import { Result, Button } from "antd"
import axios from "axios"
import NewAsyncButton from "../components/utils/NewAsyncButton"
import updateSlackJoined from "../scripts/updateSlackJoined"
import getUserData from "../scripts/getUserData"
import { auth } from "../firebase"
import dots from "../images/bgs/dots.svg"

class JoinSlack extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      authenticated: null,
    }
    this.pairToRM = this.pairToRM.bind(this)
  }

  componentDidMount() {
    auth.onAuthStateChanged(user => {
      if (!user) {
        this.setState({ authenticated: false })
      } else {
        this.setState({ authenticated: true })
        axios
          .get(
            "https://us-central1-test-51594.cloudfunctions.net/slackVerif-verifyJoined?email=" +
              user.email
          )
          .then(response => {
            if (response.data) {
              updateSlackJoined(user.uid)
                .then(() => {
                  this.pairToRM(null)
                })
                .catch(error => {
                  this.pairToRM(null)
                  console.log(error)
                })
            }
          })
          .catch(error => {
            console.log(error)
          })
      }
    })
  }

  pairToRM(reset) {
    getUserData(auth.currentUser.uid).then(userData => {
      const firebase = require("firebase")
      require("firebase/functions")
      let data = {
        uid: auth.currentUser.uid,
        userType: userData.userType,
      }

      let setUserTypeCustomClaim = firebase
        .functions()
        .httpsCallable("callables-setUserTypeCustomClaim")
      // let pairVolunteerToRM = firebase
      //   .functions()
      //   .httpsCallable("callables-pairVolunteerToRM")
      setUserTypeCustomClaim(data)
        .then(result => {
          // pairVolunteerToRM({
          //   uid: auth.currentUser.uid,
          // }).then(() => {
          if (typeof window !== "undefined") {
            if (window.fbq != null) {
              window.fbq("track", `Volunteer Registration Complete`, {
                uid: auth.currentUser.uid,
              })

              window.fbq(
                "trackCustom",
                "Volunteer Registration Complete Custom",
                { uid: auth.currentUser.uid }
              )
            }
          }
          if (reset !== null) {
            reset("Success")
            setTimeout(() => {
              navigate("/congratulations/")
            }, 700)
          } else {
            navigate("/congratulations/")
          }
          // })
        })
        .catch(error => {
          console.log(error)
          // pairVolunteerToRM({
          //   uid: auth.currentUser.uid,
          // }).then(() => {
          if (reset !== null) {
            reset("Success!")
            setTimeout(() => {
              navigate("/congratulations/")
            }, 700)
          } else {
            navigate("/congratulations/")
          }
          // })
        })
    })
  }

  verifySlack = reset => {
    let user = auth.currentUser
    if (user) {
      axios
        .get(
          "https://us-central1-test-51594.cloudfunctions.net/slackVerif-verifyJoined?email=" +
            user.email
        )
        .then(response => {
          if (response.data) {
            updateSlackJoined(user.uid)
              .then(() => {
                this.pairToRM(reset)
              })
              .catch(error => {
                console.log(error)
                this.pairToRM(reset)
              })
          } else {
            reset("An error occured. Try again?")
          }
        })
        .catch(error => {
          console.log(error)
          reset("An error occured. Try again?")
        })
    } else {
      reset("An error occured. Try again?")
    }
  }

  render() {
    return (
      <Layout image={dots} full>
        <Container className="topSpacing justify-content-center">
          <Result
            title="One More Step"
            subTitle="Click the button below to join the slack channel. Once you have joined, click 'Verify' to continue."
            extra={
              <>
                <a
                  href="https://join.slack.com/t/remoteta/shared_invite/zt-g3mkc6j8-jv62WJYSXh_yhSHzu9ETEA"
                  target="_blank"
                >
                  <Button type="primary" size="large">
                    Join Slack
                  </Button>
                </a>
                <NewAsyncButton
                  buttonVariants={["primary", "danger", "danger", "primary"]}
                  buttonTexts={[
                    "Verify",
                    "Verifying...",
                    "Error Verifying. Try Again?",
                    "Verified!",
                  ]}
                  buttonColor={["", "", "", ""]}
                  callback={this.verifySlack}
                />
              </>
            }
          />
        </Container>
      </Layout>
    )
  }
}

export default JoinSlack
